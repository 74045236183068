/**
 * This component is wrapper component for Outer layer integration.
 *
 * @module views/components/PersonalizationContent
 * @memberof -Common
 */
import React from 'react';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { hasItems } from '@ulta/core/utils/array/array';


/**
 * Represents a PersonalizationContent component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns PersonalizationContent
 */
export const PersonalizationContent = function( props ){
  const { modules } = props;

  if( !hasItems( modules ) ){
    return null;
  }

  return (
    <div className='PersonalizationContent'>
      { modules.map( ( component, index ) => (
        <AsyncComponent key={ `${component.id}:${index}` }
          { ...component }
        />
      ) ) }
    </div>
  );
};

PersonalizationContent.displayName = 'PersonalizationContent';

export default PersonalizationContent;
